/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 18, 2021 */



@font-face {
    font-family: 'akzidenzgrotesk';
    src: url('akzidenz-grotesk-roman-webfont.woff2') format('woff2'),
         url('akzidenz-grotesk-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gotham_bold';
    src: url('gotham-bold-webfont.woff2') format('woff2'),
         url('gotham-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gotham_book';
    src: url('gotham-book-webfont.woff2') format('woff2'),
         url('gotham-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gotham_medium';
    src: url('gotham-medium-webfont.woff2') format('woff2'),
         url('gotham-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}